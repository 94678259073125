import React from "react"
import '../components/layout.css'
import { useLocation } from "@reach/router"
import { NavigationBar } from "../components/NavigationBar/parent"
import MainDownload from "../components/DownloadPage/MainDownload"
import Footer from "../components/FooterComponents/Footer"
import { MainPageJson, PathJson } from "../components/location"
import Seo from "../components/SEOComponents/Seo"

function DownloadPage() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  return (
    <div className="maxwidth">
      <Seo title="download" />
      {/* <NavigationBar
        props={MainPageContent.Header}
        propsLink={PathContent}
        parentCallback={statusNavbar}
      /> */}
      <NavigationBar />
      <MainDownload />
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default DownloadPage
