import React from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import { Container, Row, Col } from "react-bootstrap"
import globeImg from '../../images/download-globe.png'
import phoneImg from '../../images/download-phone.png'
import AppStore from "../../images/image-app-store.svg"
import PlayStore from "../../images/image-play-store.svg"

function MainDownload() {
  const isEnglish = useLocation().pathname.includes("/en")
  const appstore = "https://transfez.onelink.me/e9Ud/web2a?page=send"
  const playstore = "https://transfez.onelink.me/e9Ud/web2b?page=send"
  const title = isEnglish ? "Download Transfez" : "Download Aplikasi Transfez"
  const desc = isEnglish
    ? "To experience a better way to send money worldwide with ease."
    : "Untuk memulai pengalaman mengirim uang ke berbagai destinasi dengan cepat, nyaman dan mudah."
  return (
    <MainContainer>
      <MainDiv>
        <MainTitle>{title}</MainTitle>
        <MainDesc>{desc}</MainDesc>
      </MainDiv>
      <DownloadDiv>
        <a href={appstore} target="_blank">
          <Imgstyled src={AppStore} alt="appstore" />
        </a>
        <a href={playstore} target="_blank">
          <Imgstyled src={PlayStore} alt="playstore" />
        </a>
      </DownloadDiv>
      <ImgLeft src={globeImg} alt="header" />
      <ImgRight src={phoneImg} alt="header" />
    </MainContainer>
  )
}

export default MainDownload

const MainContainer = styled(Container)`
  position: relative;
  text-align: center;
  padding-top: 200px;
  padding-bottom: 300px;
  @media (max-width: 576px){
    padding-bottom: 100px;
  }
`

const MainDiv = styled.div`
  text-align: center;
  padding: 0px 15%;
  margin-bottom: ${p => p.mbottom};
  @media (max-width: 992px) {
    padding: 0px 5%;
  }
`

const MainTitle = styled.h2`
  font-family: "Typefez-black";
  font-size: 42px;
  color: #102c57;
  margin-bottom: 1rem;
  @media (max-width: 576px) {
    font-size: 28px;
  }
`

const MainDesc = styled.h5`
  font-family: "Typefez-reg";
  color: #102c57;
  font-size: 18px;
  padding: 0px 25%;
  @media (max-width: 576px) {
    padding: 0px 10%;
  }
`

const DownloadDiv = styled.div`
  margin-top: ${p => p.mtop || "30px"};
  display: inline-block;
  flex-wrap: nowrap;
  background-color: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding: 20px;
`

const DownloadH5 = styled.p`
  font-family: "Typefez-reg";
  color: #102c57;
  font-size: 18px;
`

const Imgstyled = styled.img`
  width: 110px;
  margin: 0px 6px;
`

const ImgLeft = styled.img`
  position: absolute;
  width: 350px;
  left: -200px;
  top: 100px;
  @media (max-width: 768px) {
    display: none;
  }
  `
const ImgRight = styled.img`
  position: absolute;
  width: 350px;
  right: -200px;
  top: 100px;
  @media (max-width: 768px) {
    display: none;
  }
`